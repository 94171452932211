import invert from 'lodash/invert';
import { AggExprTypes, ExpressionTypes } from 'core/constants/report-expressions';
import {
  ColumnDataTypes, ColumnDataTypesList, ColumnModes, ColumnRenderTypes, DateColumnDataTypesList, DimensionMode, DimensionStatus, FieldEntitiesType, NumericColumnDataTypesList,
  ZeroAnchorStatus,
} from 'core/constants/report';
import { IColumn, IColumnFormatter } from 'core/models/report-response';
import {
  IColumnExprPart,
  IExpression,
  IExpressionGuarded,
  isAnd,
  isArrayJoinFn,
  isAvg,
  isAvgIf,
  isColumn,
  isComp,
  isConfig,
  isConstant,
  isCount,
  isCountDistinct,
  isCountDistinctIf,
  isCountIf,
  isDate,
  isDateDiffFn,
  isDivide,
  isIf,
  isIsNull,
  isMax,
  isMaxIf,
  isMin,
  isMinIf,
  isMinus,
  isMultiply,
  isNot,
  isNull,
  isOr,
  isPlus,
  isSum,
  isSumIf,
  newColumnExpr,
  newCountExpr,
  newMaxExpr,
  newMinExpr,
  newSumExpr,
} from 'core/models/report-expressions';
import { isValidObjectAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { FormatterMetaData, Formatters } from 'core/constants/formatters';
import { IDimension } from 'core/models/report-redux';
import { isDrilldownPossible } from './report.util';

export const aggDisplayNameTypes = { // display name to types lookup
  Sum: AggExprTypes.Sum,
  Max: AggExprTypes.Max,
  Min: AggExprTypes.Min,
  Count: AggExprTypes.Count,
  'Count Unique': AggExprTypes.CountDistinct,
};
export const aggTypeDisplayNames = invert(aggDisplayNameTypes) as { [p in AggExprTypes]: keyof typeof aggDisplayNameTypes };
export const aggDisplayNames = Object.values(aggTypeDisplayNames);

export const buildMeasureColumn = (
  id:string,
  name: string,
  Alias: string,
  DataType: ColumnDataTypes,
  aggregator: keyof typeof aggDisplayNameTypes,
  measure: IColumn,
  Entity: string,
  SchemaName: string,
  joinID?: string,
  formatter?: IColumnFormatter,
): IColumn => {
  const columnExprNew = newColumnExpr(name, Alias, DataType, joinID, Entity); // Expression SchemaName is the Alias of the builder config
  let newExpr: IExpression<ExpressionTypes.Aggregation>;
  switch (aggregator) {
    case 'Sum':
      newExpr = newSumExpr(columnExprNew);
      break;
    case 'Max':
      newExpr = newMaxExpr(columnExprNew);
      break;
    case 'Min':
      newExpr = newMinExpr(columnExprNew);
      break;
    case 'Count':
      newExpr = newCountExpr(columnExprNew);
      break;
    case 'Count Unique':
      newExpr = newCountExpr(columnExprNew, true);
      break;
    default:
      throw new Error(`unhandled aggregation ${aggregator}`);
  }

  return {
    ...measure,
    Name: name,
    Id: id,
    BuilderConfig: {
      ...measure.BuilderConfig,
      DisplayName: name,
      Entity,
      SchemaName,
      Alias,
      JoinID: joinID,
    },
    Props: {
      ...measure.Props,
      AllowDrillDown: isDrilldownPossible(newExpr),
      Formatter: isValidObjectAndNotEmpty(formatter) ? formatter : measure.Props.Formatter,
      Measure: {
        ...measure.Props.Measure,
        Name: name,
        Entity,
        Expression: {
          ...newExpr,
        },
      },
    },
  };
};
export const defaultCustomMeasureFormatter :IColumnFormatter = {
  FormatterType: Formatters.NUMBER,
  FormatterMeta: {
    [FormatterMetaData.Decimal]: 0,
    [FormatterMetaData.Seperator]: 1000,
  },
};
export const buildCustomMeasure = (name: string, mode: ColumnModes, expr: IExpression, uniqueId: string | number, formatter: IColumnFormatter, dataType:ColumnDataTypes, renderType?: ColumnRenderTypes): IColumn => <IColumn>({
  Name: name,
  Id: uniqueId, // Column Id
  Props: {
    Mode: mode,
    AllowDrillDown: isDrilldownPossible(expr),
    DrilldownEnabled: isDrilldownPossible(expr),
    Measure: {
      Name: name,
      IsMeasure: true,
      IsCustomExpression: true,
      Expression: expr,
      RenderType: renderType,
    },
    Formatter: isValidObjectAndNotEmpty(formatter) ? formatter : defaultCustomMeasureFormatter,
  },
  BuilderConfig: {
    Alias: name,
    DisplayName: name,
    DataType: dataType,
    Entity: FieldEntitiesType.Custom,
    uniqueKey: uniqueId,
  },
});

export const buildCustomDimension = (name: string,
  mode: ColumnModes,
  expr: IExpression | string,
  uniqueId: string | number,
  dataType:ColumnDataTypes,
  dimensionType: DimensionMode): IDimension => <IDimension>({
    Name: name,
    Id: uniqueId,
    IsCustomExpression: true,
    Expression: expr,
    Applied: DimensionStatus.Applied,
    ZeroAnchor: ZeroAnchorStatus.Disabled,
    Orderable: true,
    DimensionMode: dimensionType,
    Mode: mode,
    Props: {
      Mode: mode,
      AllowDrillDown: false,
      Dimension: {
        Name: name,
        Applied: DimensionStatus.Applied,
        ZeroAnchor: ZeroAnchorStatus.Disabled,
        Orderable: true,
        DimensionMode: dimensionType,
        Expression: expr,
        IsCustomExpression: true,
      },
      Formatter: null,
    },
    BuilderConfig: {
      SchemaName: name,
      Alias: name,
      DisplayName: name,
      DataType: dataType,
      Entity: FieldEntitiesType.Custom,
      uniqueKey: uniqueId,
    },
  });

export const findUnderlyingColumn = (e: IExpression): IColumnExprPart => {
  if (!e) return null;
  const expr = e as IExpressionGuarded;
  if (isColumn(expr)) return expr.Part;
  if (isConstant(expr) || isConfig(expr) || isNull(expr)) return null;
  if (isSum(expr)) return findUnderlyingColumn(expr.Part.ExpressionToSum);
  if (isSumIf(expr)) return findUnderlyingColumn(expr.Part.ExpressionToSum);
  if (isMax(expr)) return findUnderlyingColumn(expr.Part.MaxOf);
  if (isMaxIf(expr)) return findUnderlyingColumn(expr.Part.ExpressionToMax);
  if (isMin(expr)) return findUnderlyingColumn(expr.Part.MinOf);
  if (isMinIf(expr)) return findUnderlyingColumn(expr.Part.ExpressionToMin);
  if (isAvg(expr)) return findUnderlyingColumn(expr.Part.ExpressionToAvg);
  if (isAvgIf(expr)) return findUnderlyingColumn(expr.Part.ExpressionToAvg);
  if (isCount(expr)) return findUnderlyingColumn(expr.Part.ElementToCount);
  if (isCountIf(expr)) return null; // TODO: Build and return new column
  if (isCountDistinct(expr)) return findUnderlyingColumn(expr.Part.ExpressionToCount);
  if (isCountDistinctIf(expr)) return findUnderlyingColumn(expr.Part.ExpressionToCount);
  if (isPlus(expr) || isMinus(expr) || isMultiply(expr) || isDivide(expr)) return null;
  if (isComp(expr)) return null;
  if (isAnd(expr) || isOr(expr)) return null;
  if (isNot(expr) || isIsNull(expr)) return findUnderlyingColumn(expr.Part.Expression);
  if (isDate(expr) && !isDateDiffFn(expr)) return findUnderlyingColumn(expr.Part.DateTime);
  if (isDateDiffFn(expr)) return null;
  if (isArrayJoinFn(expr)) return findUnderlyingColumn(expr.Part.Array);
  if (isIf(expr)) return findUnderlyingColumn(expr.Part.Condition);

  // noinspection UnnecessaryLocalVariableJS
  /**
   * Type-exhaustiveness check breaks if a newly added expression type/subtype is not handled by this function.
   * If it is breaking, fix it by creating its own `isType(expr)` function and calling it here.
   * See https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking.
   */
  const _exhaustiveCheck: never = expr;
  return _exhaustiveCheck;
};

// function to get the formatType for a custom measure given its dataType
// @exprDataType: dataType for a custom measure
// returns: formatType for a custom measure
export const getFormatterTypeForDataType = (exprDataType: ColumnDataTypes) :Formatters => {
  if (NumericColumnDataTypesList.includes(exprDataType)) {
    return Formatters.NUMBER;
  }
  if (DateColumnDataTypesList.includes(exprDataType)) {
    if (ColumnDataTypesList.DateTime === exprDataType) {
      return Formatters.DATE; // since dateTime is not currently supported
    }
    return Formatters.TIME;
  }
  return Formatters.NUMBER;
};

// to get the dataType of the column given an expression
// @exp: expression part of a custom measure
// returns data type of the column
export const getUnderlyingColumnDateType = (exp:IExpression): ColumnDataTypes => {
  const underlyingColumn = findUnderlyingColumn(exp);
  return underlyingColumn?.Column?.DataType ?? ColumnDataTypes.Int;
};

// to get the dataType of the column given an custom dimension
// @exp: expression part of a custom dimension
// returns data type of the column
export const getUnderlyingColumnDateTypeForCustomDimension = (exp:IExpression): ColumnDataTypes => {
  const underlyingColumn = findUnderlyingColumn(exp);
  if (underlyingColumn?.Column?.DataType) {
    return underlyingColumn.Column.DataType;
  }
  if (isConstant(exp)) return exp.Part.DataType;
  return ColumnDataTypes.String;
};

// to get dataType for a given expression object of a custom measure that is mapped to a formatType.
// @expr: expression object of a custom measure
// returns: formatType for a custom measure
export const getFormattingDataTypeFromCustomExp = (expr: IExpression): Formatters => {
  if (!expr) return Formatters.NUMBER;

  if (isSum(expr) || isSumIf(expr) || isAvg(expr) || isAvgIf(expr) || isCount(expr) || isCountIf(expr) || isCountDistinct(expr)
  || isCountDistinctIf(expr) || isPlus(expr) || isMinus(expr) || isMultiply(expr) || isDivide(expr)) return Formatters.NUMBER;

  if (isConstant(expr)) return getFormatterTypeForDataType(expr.Part.DataType);

  if (isMax(expr)) return getFormatterTypeForDataType(getUnderlyingColumnDateType(expr.Part.MaxOf));
  if (isMaxIf(expr)) return getFormatterTypeForDataType(getUnderlyingColumnDateType(expr.Part.ExpressionToMax));
  if (isMin(expr)) return getFormatterTypeForDataType(getUnderlyingColumnDateType(expr.Part.MinOf));
  if (isMinIf(expr)) return getFormatterTypeForDataType(getUnderlyingColumnDateType(expr.Part.ExpressionToMin));

  return Formatters.NUMBER;
};

// function to identify the dataType of a custom dimension or measure
// @expr: expression object of a custom dimension or measure
// returns: dataType for a custom dimension or measure
export const getDataTypeFromCustomColumn = (expr: IExpression): ColumnDataTypes => {
  if (!expr) return ColumnDataTypes.Int;

  if (isSum(expr) || isSumIf(expr) || isAvg(expr) || isAvgIf(expr) || isCount(expr) || isCountIf(expr) || isCountDistinct(expr)
  || isCountDistinctIf(expr) || isPlus(expr) || isMinus(expr) || isMultiply(expr) || isDivide(expr)) return ColumnDataTypes.Int;

  if (isConstant(expr)) return expr.Part.DataType;

  if (isMax(expr)) return getUnderlyingColumnDateType(expr.Part.MaxOf);
  if (isMaxIf(expr)) return getUnderlyingColumnDateType(expr.Part.ExpressionToMax);
  if (isMin(expr)) return getUnderlyingColumnDateType(expr.Part.MinOf);
  if (isMinIf(expr)) return getUnderlyingColumnDateType(expr.Part.ExpressionToMin);
  if (isIf(expr)) return getUnderlyingColumnDateTypeForCustomDimension(expr.Part.ThenPart);

  return ColumnDataTypes.Int;
};
